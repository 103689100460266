define([
    'lodash',
    'layout/util/layout',

    // Used for legacyLayout wix apps
    'layout/util/rootLayoutUtils',
    'layout/specificComponents/balataLayout',
    'layout/specificComponents/screenWidthLayouts/screenWidthContainerLayout',
    'layout/util/singleCompLayout',
    'layout/specificComponents/matrixGalleryLayout',
    'layout/specificComponents/paginatedGridGalleryLayout',
    'layout/specificComponents/richtextLayout',
    'layout/specificComponents/slideShowLayout',
    'layout/specificComponents/sliderGalleryLayout',
    'layout/specificComponents/wPhotoLayout',
    'layout/specificComponents/imageLayout',
    'layout/util/createDOMPatchers',
    'layout/util/spaceCalculationsRegardingWixAds',

    // Side effects
    'layout/specificComponents/checkBoxLayout',
    'layout/specificComponents/fiveGridLineLayout',
    'layout/specificComponents/registerToMeasureOnly',
    'layout/specificComponents/registerToPureDomMeasure',
    'layout/specificComponents/menuLayout',
    'layout/specificComponents/verticalMenuLayout',
    'layout/specificComponents/loginSocialBarLayout',
    'layout/specificComponents/dialogLayout',
    'layout/specificComponents/siteBackgroundLayout',
    'layout/specificComponents/wixFreemiumBannerLayout',
    'layout/specificComponents/documentMediaLayout',
    'layout/specificComponents/shapeLayout',
    'layout/specificComponents/tinyMenuLayout',
    'layout/specificComponents/imageZoomLayout',
    'layout/specificComponents/tpaGalleryLayout',
    'layout/specificComponents/formMixinLayout',
    'layout/specificComponents/bgImageLayout',
    'layout/specificComponents/wixSiteLayout',
    'layout/specificComponents/fitToContentLayout',
    'layout/specificComponents/imageButtonLayout',
    'layout/specificComponents/boxSlideShowLayout',
    'layout/specificComponents/mediaContainerLayout',
    'layout/specificComponents/mediaControlsLayout',
    'layout/specificComponents/mediaOverlayControlsLayout',
    'layout/specificComponents/columnsLayout',
    'layout/specificComponents/popupContainerLayout',
    'layout/specificComponents/rssButtonLayout',
    'layout/specificComponents/tpaLayout',
    'layout/specificComponents/inlinePopupLayout',
    'layout/specificComponents/inlinePopupToggleLayout',

    // Full width
    'layout/specificComponents/screenWidthLayouts/stripsComponentsLayout',
    'layout/specificComponents/screenWidthLayouts/headerLayout',
    'layout/specificComponents/screenWidthLayouts/footerLayout',
    'layout/specificComponents/screenWidthLayouts/pageLayout',
    'layout/specificComponents/screenWidthLayouts/pagesContainerLayout',
    'layout/specificComponents/screenWidthLayouts/pageGroupLayout',
    'layout/specificComponents/screenWidthLayouts/stripSlideshowLayout',

    'layout/specificComponents/quickActionBarLayout',
    'layout/specificComponents/quickActionBarItemLayout',
    'layout/specificComponents/expandableMenuLayout',
    'layout/specificComponents/touchMediaZoomLayout',
    'layout/specificComponents/siteButtonLayout'
], function (
    _,
    layout,
    rootLayoutUtils,
    balataLayout,
    screenWidthContainerLayout,
    singleCompLayout,
    matrixGalleryLayout,
    paginatedGridGalleryLayout,
    richtextLayout,
    slideShowLayout,
    sliderGalleryLayout,
    wPhotoLayout,
    imageLayout,
    createDOMPatchers,
    spaceCalculationsRegardingWixAds
) {
    'use strict';

    return _.assign(layout, {singleCompLayout, rootLayoutUtils, spaceCalculationsRegardingWixAds, createDOMPatchers, specificComponents: {
        balataLayout,
        screenWidthContainerLayout,
        matrixGalleryLayout,
        paginatedGridGalleryLayout,
        richtextLayout,
        slideShowLayout,
        sliderGalleryLayout,
        wPhotoLayout,
        imageLayout
    }}
    );
});
